import { RemixBrowser } from '@remix-run/react';
import { initClientAmplitude } from '@shiftsmartinc/remix-utils';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { AMPLITUDE_KEY } from '~/global/config';
import * as datadog from '~/global/config/datadog';

/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});

// We want this as early as possible
datadog.init();

initClientAmplitude(AMPLITUDE_KEY);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/worker.js');
  });

  /**
   * Prevents the default mini-infobar or install dialog from appearing on
   * mobile, storing the original event so we can use it later in the users
   * journey.
   */
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    global.deferredPrompt = e;
  });
}
